@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");

body {
  font-family: "Roboto Mono", monospace;
}

.loading-dots span {
  animation: bounce 1s infinite;
}

.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

.swal-popup-dark-cyan {
  background-color: #003f5c; /* Dark cyan */
  color: white;
  border-radius: 10px;
}

.swal-title-dark-cyan {
  color: #ff6361; /* Accent color */
}

.swal-content-dark-cyan {
  color: #ffa600;
}

.swal-button-dark-cyan {
  background-color: #ff6361;
  color: white;
  border: none;
  border-radius: 5px;
}


@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

:root {
  /* colors */
  --primary-100: #dbeafe;
  --primary-200: #bfdbfe;
  --primary-300: #a5b4fc;
  --primary-400: #818cf8;
  --primary-500: #6366f1;
  --primary-600: #4f46e5;
  --primary-700: #4338ca;
  --primary-800: #3730a3;
  --primary-900: #312e81;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;
  --fluid-width: 90vw;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
/* 
  ============= 
  Navbar
  =============== 
  */

nav {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-center {
  width: var(--fluid-width);
  max-width: var(--max-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  text-transform: none;
  color: var(--white);
  letter-spacing: 3px;
  font-weight: 700;
}

.toggle-btn {
  width: 2rem;
  height: 2rem;
  background: var(--white);
  color: var(--primary-700);
  border-radius: var(--borderRadius);
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}

.toggle-btn:hover {
  transform: scale(1.05);
}

/* 
    ============= 
    Hero
    =============== 
    */
.hero-container {
  min-height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
}

.hero-center {
  color: var(--white);
  width: var(--fluid-width);
  max-width: var(--max-width);
}

.hero-center h1 {
  font-size: clamp(3rem, 5vw, 5rem);
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1.25;
}

.hero-center p {
  line-height: 1.5;
}

@media screen and (min-width: 992px) {
  .hero-center {
    text-align: center;
  }

  .hero-center p {
    max-width: 35em;
    margin: 0 auto;
  }

  .toggle-btn {
    display: none;
  }
}

/* 
    ============= 
    Sidebar
    =============== 
    */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--white);
  /* toggle */
  opacity: 0;
  visibility: hidden;
}

.show-sidebar {
  opacity: 1;
  visibility: visible;
  transition-property: opacity;
  transition-duration: 1s;
}

/* toggle class */

.sidebar-container {
  padding: 4rem 2rem;
  position: relative;
}

.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--primary-500);
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.sidebar article {
  margin-bottom: 2rem;
}

.sidebar h4 {
  margin-bottom: 1rem;
  color: var(--primary-700);
}

.sidebar-sublinks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.5rem;
}

.sidebar-sublinks a {
  display: block;
  color: var(--grey-900);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sidebar-sublinks svg {
  color: var(--grey-500);
}

@media screen and (min-width: 992px) {
  .sidebar {
    display: none;
  }
}

/* 
    ============= 
    Nav Links
    =============== 
    */
.nav-links {
  display: none;
}

@media screen and (min-width: 992px) {
  .nav-links {
    display: flex;
    justify-content: center;

    align-self: stretch;
  }

  .nav-center {
    align-self: stretch;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .nav-link {
    padding: 0 1rem;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
    cursor: pointer;
  }
}

/* 
    ============= 
    Submenu
    =============== 
    */
.submenu {
  display: none;
}

@media screen and (min-width: 992px) {
  .submenu {
    display: block;
    position: fixed;
    top: 7rem;
    left: 50%;
    width: var(--fluid-width);
    max-width: var(--max-width);
    background: rgb(22 78 99);

    padding: 2rem;
    transform: rotateX(-90deg) translateX(-50%);
    transform-origin: top;
    perspective: 1000px;
    border-radius: var(--borderRadius);
    visibility: hidden;
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
    z-index: -1;
  }

  .show-submenu {
    visibility: visible;
    opacity: 1;
    transform: rotateX(0deg) translateX(-50%);
    z-index: 1000000 !important;
  }

  .submenu h5 {
    margin-bottom: 1rem;
    /* color: var(--primary-700); */
  }

  .submenu-links {
    display: grid;
    row-gap: 0.5rem;
  }

  .submenu-links a {
    display: block;
    /* color: var(--grey-900); */
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white !important;
  }

  .submenu-links svg {
    color: var(--grey-500);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-animation {
  animation: rotate 20s linear infinite;
}
