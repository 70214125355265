.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
}

.icon-container {
  margin-bottom: 1rem;
}

.not-found-icon {
  color: #e53e3e;
  font-size: 6rem;
  animation: bounce 1.5s infinite;
}

.not-found-title {
  font-size: 2rem;
  font-weight: 700;
  color: #2d3748;
}

.not-found-text {
  font-size: 1.25rem;
  color: #4a5568;
  margin-bottom: 2rem;
}

.not-found-link {
  font-size: 1.25rem;
  color: #4299e1;
  border: 2px solid #4299e1;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s, color 0.3s;
}

.not-found-link:hover {
  background-color: #4299e1;
  color: #ffffff;
}

/* Animations */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .not-found-icon {
    font-size: 4rem;
  }

  .not-found-title {
    font-size: 1.5rem;
  }

  .not-found-text {
    font-size: 1rem;
  }

  .not-found-link {
    font-size: 1rem;
  }
}
